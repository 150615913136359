export default defineAppConfig({
  apiBaseAI: import.meta.env.VITE_PUBLIC_API_BASE_AI,
  apiBaseNet: import.meta.env.VITE_PUBLIC_API_BASE_NET,
  videoExt: [
    { ext: 'mp4', mime: 'video/mp4', browserSupport: true },
    { ext: 'mov', mime: 'video/quicktime', browserSupport: true },
    { ext: 'mpeg', mime: 'video/mpeg', browserSupport: true },
    { ext: 'webm', mime: 'video/webm', browserSupport: true },

    // 新增支持
    //
    { ext: '3gp', mime: 'video/3gpp', browserSupport: true },
    { ext: 'wmv', mime: 'video/x-ms-wmv', browserSupport: false },
    { ext: 'asf', mime: 'video/x-ms-asf', browserSupport: false },
    { ext: 'avi', mime: 'video/x-msvideo', browserSupport: false },
    { ext: 'avi', mime: 'video/avi', browserSupport: false },
    { ext: 'mkv', mime: 'video/x-matroska', browserSupport: false },
    { ext: 'm4v', mime: 'video/x-m4v', browserSupport: true },
    { ext: 'ts', mime: 'video/mp2t', browserSupport: false },
    { ext: 'ts', mime: 'video/mp2ts', browserSupport: false },
  ],
  imageExt: [
    { ext: 'jpg', mime: 'image/jpeg' },
    { ext: 'jpeg', mime: 'image/jpeg' },
    { ext: 'png', mime: 'image/png' },
    { ext: 'gif', mime: 'image/gif' },
    { ext: 'webp', mime: 'image/webp' },
    { ext: 'bmp', mime: 'image/bmp' },
  ],
  // 视频最大时长30分钟
  maxVideoDuration: 30 * 60 * 1000 * 1000,
  // 视频裁剪最大时长30分钟
  maxTrimVideoDuration: 30 * 60 * 1000 * 1000,
  // 大文件阈值30MB
  LARGE_FILE_THRESHOLD: 30 * 1024 * 1024,
  // 图片尺寸限制
  IMAGE_CONSTRAINTS: {
    MIN_DIMENSION: 160, // 最小尺寸
    MAX_DIMENSION: 4000, // 最大尺寸
    MIN_RATIO: 9 / 21, // 最小比例
    MAX_RATIO: 21 / 9 // 最大比例
  },
  // GIF图支持最大秒数
  MAX_GIF_DURATION: 15 * 1000,
  IMGTOOL_URL: import.meta.env.VITE_WEB === 'dev' ? "http://localhost:3000/bfs" : (import.meta.env.VITE_WEB !== 'prod' ? 'http://test.imgtool.pro/bfs' : 'https://imgtool.pro/bfs'),
  // 应用商店链接
  APP_STORE: {
    IOS_URL: 'https://apps.apple.com/us/app/cartoonai-photo-ai/id6740602229',
    ANDROID_URL: 'https://play.google.com/store/apps/details?id=com.faceswap.pro.android'
  }
});
